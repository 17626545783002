import Vue from "vue";

export default {
  recuperarCep(cep) {
    return Vue.axios.get("cep", { params: { cep: cep } });
  },

  recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
    return Vue.axios.post("cep/filtro", {
      params: {
        cep: paramsFiltro.cep,
        logradouro: paramsFiltro.logradouro,
        bairro: paramsFiltro.bairro,
        municipio: paramsFiltro.municipio,
        uf: paramsFiltro.uf
      },
      pagina,
      numElementosPagina,
      ordenacao
    });
  },

  atualizarCep(dados) {
    return Vue.axios.put("cep/" + dados.oid, {
      cep: dados.cep,
      logradouro: dados.logradouro,
      bairro: dados.bairro,
      cidade: dados.cidade,
      uf: dados.uf
    });
  },

  criarCep(dados) {
    return Vue.axios.post("cep", {
      cep: dados.cep,
      logradouro: dados.logradouro,
      bairro: dados.bairro,
      cidade: dados.cidade,
      uf: dados.uf
    });
  },

  criaCepApi(viaCep, cep) {
    return Vue.axios.post("cep/cepApi", {
      cep: cep,
      logradouro: viaCep.logradouro,
      bairro: viaCep.bairro,
      localidade: viaCep.localidade,
      estado: viaCep.uf
    });
  }
};
