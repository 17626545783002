var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.campos),function(campo,i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[(campo.text.length)?_c('v-text-field',{attrs:{"readonly":"","small":"","dense":"","outlined":"","value":_vm.converterCampo(_vm.entidade[campo.value]),"label":campo.text}}):_vm._e()],1)}),1),(_vm.paramsFiltro.entidadeOid !== _vm.entidade.oid)?_c('v-row',{staticClass:"mb-2 ml-2",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"accent","small":"","outlined":""},on:{"click":function($event){_vm.auditoriaEntidade = _vm.anteriorCampoEntidade; _vm.voltar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left-bold ")]),_vm._v(" Voltar ")],1),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.auditoriaEntidade)+" - "+_vm._s(_vm.auditoriaEntidadeOid)+" ")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [25, 50, 100, 200, 500]
          },"headers":_vm.headers,"items":_vm.elementos,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.numTotalElementos,"calculate-widths":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.defaultValue(header, value))+" ")]}}}),{key:"item.data",fn:function(ref){
          var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(value)))])]}},{key:"item.valorAnterior",fn:function(ref){
          var item = ref.item;
          var value = ref.value;
          var header = ref.header;
return [(item.valorAnterior && value.includes('[',']') && !value.includes('[]'))?_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.stringToList(value)),function(valor,i){return _c('v-chip',{key:i,staticStyle:{"white-space":"pre-line","height":"auto"},attrs:{"small":"","color":value.length <= 2 || _vm.possuiValor(valor, _vm.stringToList(item.valorAtual)) ?  '' : 'error'}},[_vm._v(" "+_vm._s(valor)+" ")])}),1):(value && !value.includes('[]'))?_c('v-chip',{staticStyle:{"white-space":"pre-line","height":"auto"},attrs:{"small":"","color":item.valorAtual ? '' : 'error'}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.defaultValue(header, value))+" ")])]}},{key:"item.valorAtual",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
          var value = ref.value;
          var header = ref.header;
return [(item.valorAtual && value.includes('[',']') && !value.includes('[]'))?_c('v-chip-group',{attrs:{"column":""}},[_c('v-container',{staticClass:"justify-center",attrs:{"fluid":""}},_vm._l((_vm.stringToList(value)),function(valor,i){return _c('v-chip',{key:i,staticStyle:{"white-space":"pre-line","height":"auto"},attrs:{"small":"","color":value.length <= 2 || _vm.possuiValor(valor, _vm.stringToList(item.valorAnterior)) ?  '' : 'success'}},[_vm._v(" "+_vm._s(valor)+" ")])}),1)],1):(value && !value.includes('[]'))?_c('v-chip',{staticStyle:{"white-space":"pre-line","height":"auto"},attrs:{"small":"","color":item.valorAnterior ? 'accent' : 'success'}},[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.defaultValue(header, value))+" ")]),(item.campoEntidade && !item.isValorAtualOid)?_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","x-small":"","color":"accent"},on:{"click":function($event){_vm.anteriorOid.push(_vm.paramsFiltro.entidadeOid); _vm.auditar(item.valorAtual, index)}}},[_c('v-icon',[_vm._v(" mdi-arrow-top-right-thin-circle-outline ")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }