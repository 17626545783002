import Vue from "vue";

export default {
  recuperarEstados() {
    return Vue.axios.get("uf");
  },

  recuperarUFBySigla(uf){
    return Vue.axios.get("/uf/uf/" + uf);
  }
};
