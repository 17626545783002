import Vue from "vue";

export default {

    recuperarFiltro(paramsFiltro, pagina, numElementosPagina, ordenacao) {
        return Vue.axios.post("auditoria/filtro", {
            params: {
                entidadeOid: paramsFiltro.entidadeOid
            },
            pagina,
            numElementosPagina,
            ordenacao
        });
    }

}