<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" v-for="(campo, i) in campos" :key="i">
        <v-text-field v-if="campo.text.length" readonly small dense outlined
                      :value="converterCampo(entidade[campo.value])"
                      :label="campo.text"/>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-2 ml-2" v-if="paramsFiltro.entidadeOid !== entidade.oid">
      <v-btn @click="auditoriaEntidade = anteriorCampoEntidade; voltar()"
             color="accent"
             small
             outlined
      >
        <v-icon left>
          mdi-arrow-left-bold
        </v-icon>
        Voltar
      </v-btn>
      <v-card-title>
        {{ auditoriaEntidade }} - {{ auditoriaEntidadeOid }}
      </v-card-title>
    </v-row>
    <v-data-table
        :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [25, 50, 100, 200, 500]
            }"
        :headers="headers"
        :items="elementos"
        :loading="loading"
        :options.sync="options"
        :server-items-length="numTotalElementos"
        calculate-widths
        class="elevation-1"
        dense
    >
      <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ value }">
        {{ defaultValue(header, value) }}
      </template>
      <template v-slot:item.data="{ value }">
        <span> {{ formatDate(value) }}</span>
      </template>
      <template v-slot:item.valorAnterior="{ item, value, header }">
        <v-chip-group v-if="item.valorAnterior && value.includes('[',']') && !value.includes('[]')" column>
            <v-chip small
                    :color="value.length <= 2 || possuiValor(valor, stringToList(item.valorAtual)) ?  '' : 'error'"
                    v-for="(valor, i) in stringToList(value)" :key="i"
                    style="white-space: pre-line; height: auto">
              {{ valor }}
            </v-chip>
        </v-chip-group>
        <v-chip small v-else-if="value && !value.includes('[]')" :color="item.valorAtual ? '' : 'error'"
                style="white-space: pre-line; height: auto">
          {{ value }}
        </v-chip>
        <span v-else> {{ defaultValue(header, value) }} </span>
      </template>
      <template v-slot:item.valorAtual="{ item, index, value, header }">
        <v-chip-group v-if="item.valorAtual && value.includes('[',']') && !value.includes('[]')" column>
          <v-container fluid class="justify-center">
          <v-chip small
                  :color="value.length <= 2 || possuiValor(valor, stringToList(item.valorAnterior)) ?  '' : 'success'"
                  v-for="(valor, i) in stringToList(value)" :key="i"
                  style="white-space: pre-line; height: auto">
            {{ valor }}
          </v-chip>
          </v-container>
        </v-chip-group>
        <v-chip small v-else-if="value && !value.includes('[]')" :color="item.valorAnterior ? 'accent' : 'success'"
                style="white-space: pre-line; height: auto">
          {{ value }}
        </v-chip>
        <span v-else> {{ defaultValue(header, value) }} </span>
        <v-btn v-if="item.campoEntidade && !item.isValorAtualOid"
               icon
               x-small
               color="accent"
               class="ml-1"
               @click="anteriorOid.push(paramsFiltro.entidadeOid); auditar(item.valorAtual, index)">
          <v-icon>
            mdi-arrow-top-right-thin-circle-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import auditoriaService from "@/services/auditoria.service";
import luxon from "@/core/luxon";

export default {
  name: "AuditoriaFiltro",
  props: {
    entidade: {type: Object, required: true},
    campos: {type: Array, required: true}
  },
  data() {
    return {
      loading: false,
      headers: [
        {text: "Data", value: "data"},
        {text: "CPF Responsavel", value: "cpfResponsavel", sortable: false},
        {text: "Perfil", value: "perfil", sortable: false},
        {text: "Campo", value: "campo", sortable: false},
        {text: "Valor Anterior", value: "valorAnterior", sortable: false},
        {text: "Valor Atual", value: "valorAtual", sortable: false},
        {text: "Operação", value: "operacao", sortable: false}
      ],
      sortBy: ["data"],
      sortDesc: [false],
      paramsFiltro: {},
      numTotalElementos: 0,
      elementos: [],
      options: {},
      anteriorOid: [],
      auditoriaEntidade: null,
      auditoriaEntidadeOid: null,
      anteriorCampoEntidade: null
    };
  },

  async mounted() {

    this.auditar(this.entidade.oid);

  },

  methods: {
    converterCampo(valor) {
      if (typeof valor === "boolean") {
        return valor ? 'Sim' : 'Não';
      }
      return valor;
    },

    formatDate(data) {
      return data
          ? luxon.dataConverter(data).toFormat("dd/LL/yyyy HH:mm")
          : "";
    },

    defaultValue(header, value) {
      if (!value || value === "[]") {
        header.align = "center";
        return "-";
      }

      return value;
    },

    voltar() {
      this.auditar(this.anteriorOid.pop());
    },

    auditar(oid, index = 0) {
      if (this.elementos) {
        this.anteriorCampoEntidade = this.auditoriaEntidade;
        this.auditoriaEntidade = this.elementos.length ? this.elementos[index].campoEntidade : null;
        this.auditoriaEntidadeOid = oid;
      }
      this.paramsFiltro.entidadeOid = oid;
      this.options.page = 1;
      this.getDataFromApi();
    },

    async getDataFromApi() {
      this.loading = true;

      this.elementos = [];
      this.numTotalElementos = 0;

      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      await auditoriaService.recuperarFiltro(
          this.paramsFiltro,
          page - 1,
          itemsPerPage,
          ordenacao
      )
          .then(response => {
            this.elementos = response.data.elementos;
            this.numTotalElementos = response.data.numTotalElementos;
          })
          .catch(() => {
            this.loading = false;
          });

      this.loading = false;
    },

    stringToList(string) {
      let strings = string.replace(/[\][']+/g, '').split(',');

      strings.forEach((s, index) => {
        if (s.length > 75) {
          strings[index] = s.match(/.{1,75}/g).join('\n');
        }
      });

      return strings;
    },

    possuiValor(valor, lista) {
      return lista.includes(valor);
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },

};
</script>

<style scoped>
.v-chip {
  margin-left: auto;
  margin-right: auto;
}
</style>