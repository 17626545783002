var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{attrs:{"rounded":"","width":"100%"}},[_c('v-card-title',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"titulo justify-end"},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-card',{attrs:{"flat":"","width":"100%"}},[_c('v-form',{ref:"consultaParams",on:{"submit":function($event){$event.preventDefault();return _vm.consultar()}}},[_c('v-card-text',[_vm._t("areaParametros")],2),_c('v-card-actions',[_vm._t("areaParametrosBotoesExtra"),_c('v-spacer'),(_vm.possuiBotaoConsulta)?_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Consultar ")],1):_vm._e()],2)],1)],1)],1)],1)],1),_c('v-divider'),(_vm.mostrarResultado)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [25, 50, 100, 200, 500]
          },"headers":_vm.headersConsulta,"items":_vm.elementos,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.numTotalElementos,"sort-by":_vm.ordenacaoColunas,"sort-desc":_vm.sortDesc,"calculate-widths":"","dense":"","multi-sort":"","expanded":_vm.expanded,"single-expand":true,"show-expand":_vm.mostrarExpandir,"item-key":_vm.itemKey},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.ordenacaoColunas=$event},"update:sort-by":function($event){_vm.ordenacaoColunas=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"mr-0",attrs:{"text":"","color":"primary","disabled":!_vm.elementos},on:{"click":_vm.exportarTabela}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1),_c('v-btn',{staticClass:"mr-5",attrs:{"text":"","color":"pdf","disabled":!_vm.elementos},on:{"click":_vm.exportarPdf}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v(" Exportar ")],1)]},proxy:true},_vm._l((_vm.headersConsulta.filter(function (h) { return !h.transient; })),function(header){return {key:("item." + (header.value)),fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.defaultValue(header, value))+" ")]}}}),_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}}),{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":"","offset-x":"","top":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_vm._t("acoesMenu",null,{"item":item}),_c('v-list-item',[_c('v-btn',{attrs:{"block":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.auditoria(item)}}},[_vm._v(" Auditoria ")])],1)],2)],1)]}}],null,true)})],1):_vm._e()],1)],1),(_vm.auditoriaDialog)?_c('v-dialog',{attrs:{"persistent":"","scrollable":"","width":"80%"},model:{value:(_vm.auditoriaDialog),callback:function ($$v) {_vm.auditoriaDialog=$$v},expression:"auditoriaDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary justify-start white--text"},[_vm._v(" Auditoria "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.auditoriaDialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('Auditoria',{attrs:{"entidade":_vm.entidadeAuditoria,"campos":_vm.colunas}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }