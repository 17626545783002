<template>
  <v-container fluid>
    <v-row justify="center">
      <v-card rounded width="100%">
        <v-card-title>
          <v-container fluid>
            <v-card-title class="titulo justify-end">
              {{ titulo }}
            </v-card-title>
            <v-row dense justify="center">
              <v-card flat width="100%">
                <v-form ref="consultaParams" @submit.prevent="consultar()">
                  <v-card-text>
                    <slot name="areaParametros"/>
                  </v-card-text>
                  <v-card-actions>
                    <slot name="areaParametrosBotoesExtra"/>
                    <v-spacer/>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        type="submit"
                        v-if="possuiBotaoConsulta"
                    >
                      <v-icon left>mdi-magnify</v-icon>
                      Consultar
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-row>
          </v-container>
        </v-card-title>
        <v-divider/>
        <v-card-text v-if="mostrarResultado">
          <v-data-table
              :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [25, 50, 100, 200, 500]
            }"
              :headers="headersConsulta"
              :items="elementos"
              :loading="loading"
              :options.sync="options"
              :server-items-length="numTotalElementos"
              :sort-by.sync="ordenacaoColunas"
              :sort-desc.sync="sortDesc"
              calculate-widths
              class="elevation-1"
              dense
              multi-sort
              :expanded.sync="expanded"
              :single-expand="true"
              :show-expand="mostrarExpandir"
              :item-key="itemKey"
          >
            <template v-slot:footer.prepend>
              <v-spacer/>
              <v-btn
                  text
                  class="mr-0"
                  color="primary"
                  @click="exportarTabela"
                  :disabled="!elementos"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
                Exportar
              </v-btn>
              <v-btn
                  text
                  class="mr-5"
                  color="pdf"
                  @click="exportarPdf"
                  :disabled="!elementos"
              >
                <v-icon>mdi-file-pdf-box</v-icon>
                Exportar
              </v-btn>
            </template>
            <template
                v-for="header in headersConsulta.filter(h => !h.transient)"
                v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ defaultValue(header, value) }}
            </template>
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
              <slot :name="slot" v-bind="props"/>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu
                  close-on-click
                  offset-x
                  top
                  transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="accent" icon x-small>
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <slot name="acoesMenu" v-bind:item="item"/>
                  <v-list-item>
                    <v-btn block text @click.prevent="auditoria(item)">
                      Auditoria
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-dialog
        v-model="auditoriaDialog"
        v-if="auditoriaDialog"
        persistent
        scrollable
        width="80%"
    >
      <v-card>
        <v-card-title class="primary justify-start white--text">
          Auditoria
          <v-spacer/>
          <v-btn icon color="white" @click="auditoriaDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <Auditoria :entidade="entidadeAuditoria" :campos="colunas"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import XLSX from "xlsx";
import Auditoria from "@/components/pages/admin/consultas/Auditoria";
import {mapActions} from "vuex";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

export default {
  name: "Consulta",
  components: {Auditoria},
  props: {
    titulo: {type: String, required: true},
    colunas: {type: Array, required: true},
    parametros: {type: Object, required: true},
    ordenacaoColunas: {type: Array, required: false},
    consultaCallback: {type: Function, required: true},
    mostrarExpandir: {type: Boolean, required: false, default: false},
    itemKey: {type: String, required: false, default: ""},
    possuiBotaoConsulta: {type: Boolean, required: false, default: true},
    nomeExportacaoPDF: {type: String, required: false, default: "Relatorio"}
  },

  data() {
    return {
      mostrarResultado: false,
      numTotalElementos: 0,
      elementos: [],
      loading: false,
      dialog: false,
      options: {},
      sortDesc: [false],
      expanded: [],
      auditoriaDialog: false,
      entidadeAuditoria: null
    };
  },

  computed: {
    headersConsulta() {
      let headers = this.colunas;
      headers.push({
        text: "",
        value: "actions",
        transient: true,
        sortable: false,
        align: "center"
      });
      return headers;
    }
  },

  watch: {
    options: {
      handler() {
        this.$nextTick(() => {
          window.scrollTo({top: 0, behavior: "smooth"});
        });

        this.getDataFromApi();
      },
      deep: true
    }
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),

    defaultValue(header, value) {
      if (typeof value !== "number" && !value) {
        header.align = "center";
        return "-";
      }

      return value;
    },

    consultar() {
      if (this.$refs.consultaParams.validate()) {
        if (this.mostrarResultado) {
          this.getDataFromApi();
        }

        this.options.page = 1;

        this.mostrarResultado = true;
      }
    },

    async getDataFromApi() {
      this.loading = true;

      this.elementos = [];
      this.numTotalElementos = 0;

      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      await this.consultaCallback(
          this.parametros,
          page - 1,
          itemsPerPage,
          ordenacao
      )
          .then(response => {
            this.elementos = response.data.elementos;
            this.numTotalElementos = response.data.numTotalElementos;
          })
          .catch(() => {
            this.exibirAviso({
              mensagem: "Não foi possível retornar um resultado para consulta",
              tipo: "error"
            });
            this.loading = false;
          });

      this.loading = false;
    },

    async exportarTabela() {
      let loader = this.$loading.show();

      const {sortBy, sortDesc, page} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      let resultado;

      await this.consultaCallback(
          this.parametros,
          page - 1,
          999999999,
          ordenacao
      )
          .then(response => {
            resultado = response.data.elementos;
          })
          .catch(() => {
            loader.hide();
          });

      if (resultado) {
        let header = this.colunas.map(h => h.value);
        let dataRows = [];
        resultado.map(row => {
          let dataRow = [];

          header.map(column => {
            dataRow.push(row[column]);
          });
          dataRows.push(dataRow);
        });

        let nomes = this.colunas.map(h => h.text);

        const ws = XLSX.utils.aoa_to_sheet([nomes, ...dataRows]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.titulo);
        XLSX.writeFile(wb, this.titulo + ".xlsx");
      }

      loader.hide();
    },

    async exportarPdf(){
      let loader = this.$loading.show();

      const {sortBy, sortDesc, page} = this.options;

      let ordenacao = [];

      for (let i = 0; i < sortBy.length; i++) {
        ordenacao.push({direcao: +sortDesc[i], coluna: sortBy[i]});
      }

      let resultado;

      await this.consultaCallback(
          this.parametros,
          page - 1,
          999999999,
          ordenacao
      )
          .then(response => {
            resultado = response.data.elementos;
          })
          .catch(() => {
            loader.hide();
          });

      if (resultado) {
        let header = this.colunas.filter(h => !h.transient).map(h => h.value);
        let dataRows = [];
        resultado.map(row => {
          let dataRow = [];
          header.map(column => {
            dataRow.push(row[column] ?? '-');
          });
          dataRows.push(dataRow);
        });

        let nomes = this.colunas.filter(c => !c.transient).map(h => h.text);
        let doc = new jsPDF('portrait', 'pt', 'a4')
        doc.text(this.nomeExportacaoPDF + ' - ' + this.titulo,185,20)
        autoTable(doc, {head: [nomes], body: dataRows,
          headStyles: {
            fillColor: [104,186,134],
            fontSize: 7,
            margin: 25,
          },
          styles: {
            fontSize: 7,
          }
      });
        doc.save(this.titulo + " - " + this.nomeExportacaoPDF + ".pdf");
      }

      loader.hide();
    },

    auditoria(entidade) {
      this.entidadeAuditoria = entidade;
      this.auditoriaDialog = true;
    }
  }
};
</script>

<style scoped></style>
