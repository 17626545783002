<template>
  <Consulta
      ref="filtroConsulta"
      titulo="Consulta de CEP"
      :parametros="paramsFiltro"
      :colunas="headers"
      :ordenacao-colunas="sortBy"
      :consulta-callback="consulta"
  >
    <template v-slot:areaParametros>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
              v-model="paramsFiltro.cep"
              dense
              v-mask="'########'"
              :counter="8"
              maxlength="8"
              label="CEP"
              name="cepFiltro"
              outlined
              validate-on-blur
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="paramsFiltro.logradouro"
              dense
              label="Logradouro"
              name="logradouroCepFiltro"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="paramsFiltro.bairro"
              dense
              label="Bairro"
              name="bairroCepFiltro"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="paramsFiltro.municipio"
              dense
              label="Município"
              name="municipioCepFiltro"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
              v-model="paramsFiltro.uf"
              dense
              label="UF"
              name="ufCepFiltro"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:areaParametrosBotoesExtra>
      <v-dialog
          v-model="dialog"
          width="500"
          @click:outside="cancelarEdicaoCep"
          scrollable
          persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary">
            <v-icon left>mdi-plus-thick</v-icon>
            Adicionar
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary justify-start white--text">
            {{ isCriandoCep ? "Novo CEP" : "Atualizar CEP" }}
            <v-spacer/>
            <v-btn icon color="white" @click="cancelarEdicaoCep">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
                ref="edicaoCEPForm"
                @submit.prevent="salvarEdicaoCEP"
                class="mt-3"
            >
              <v-text-field
                  v-model="cepEditado.cep"
                  v-mask="'########'"
                  :counter="8"
                  :rules="[campoObrigatorioRule, tamanhoCEPRule]"
                  dense
                  label="CEP *"
                  maxlength="8"
                  name="cepCadastro"
                  outlined
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  v-model="cepEditado.logradouro"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Logradouro *"
                  name="logradouroCadastro"
                  outlined
                  validate-on-blur
              ></v-text-field>
              <v-text-field
                  v-model="cepEditado.bairro"
                  :rules="[campoObrigatorioRule]"
                  dense
                  label="Bairro *"
                  name="bairroCadastro"
                  outlined
                  validate-on-blur
              ></v-text-field>
              <v-select
                  v-model="cepEditado.uf"
                  :items="estados"
                  :rules="[campoObrigatorioRule]"
                  dense
                  item-text="nome"
                  label="UF *"
                  name="cepCadastro"
                  outlined
                  return-object
                  validate-on-blur
              ></v-select>
              <v-select
                  v-model="cepEditado.cidade"
                  :items="municipiosPorEstado"
                  :rules="[campoObrigatorioRule]"
                  dense
                  item-text="nome"
                  label="Município *"
                  name="municipioCadastro"
                  outlined
                  return-object
                  validate-on-blur
                  :disabled="!cepEditado.uf"
              ></v-select>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click.prevent="cancelarEdicaoCep">
                  Cancelar
                </v-btn>
                <v-btn color="primary" type="submit">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:acoesMenu="{ item }">
      <v-btn block text @click="editarCep(item)">
        Editar
      </v-btn>
    </template>
  </Consulta>
</template>

<script>
import Consulta from "@/components/pages/admin/consultas/Consulta";
import cepService from "@/services/cep.service";
import {mapActions} from "vuex";
import rules from "@/commons/rules";
import ufService from "@/services/uf.service";
import municipioService from "@/services/municipio.service";

export default {
  name: "Cep",

  components: {Consulta},

  data() {
    return {
      dialog: false,
      headers: [
        {text: "CEP", value: "cep", mustSort: true},
        {text: "Logradouro", value: "logradouro"},
        {text: "Bairro", value: "bairro"},
        {text: "Município", value: "municipio"},
        {text: "UF", value: "uf"}
      ],
      sortBy: ["cep"],
      paramsFiltro: {},
      cepEditado: {},
      estados: [],
      municipios: [],
      consulta: cepService.recuperarFiltro
    };
  },

  async created() {
    await ufService
        .recuperarEstados()
        .then(response => {
          this.estados = response.data;
        })
        .catch(() => {
        });

    await municipioService
        .recuperarMunicipios()
        .then(response => {
          this.municipios = response.data;
        })
        .catch(() => {
        });
  },

  computed: {
    isCriandoCep() {
      return !this.cepEditado.oid;
    },

    municipiosPorEstado() {
      if (this.cepEditado.uf) {
        return this.municipios.filter(
            municipio => municipio.codigoEstado === this.cepEditado.uf.codigo
        );
      }
      return [];
    }
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
    ...rules,

    async editarCep(cep) {
      await cepService
          .recuperarCep(cep.cep)
          .then(response => {
            this.cepEditado = response.data;
          })
          .catch(() => {
          });

      this.dialog = true;
    },

    async salvarEdicaoCEP() {
      if (this.$refs.edicaoCEPForm.validate()) {
        let loader = this.$loading.show();

        if (!this.isCriandoCep) {
          await cepService
              .atualizarCep(this.cepEditado)
              .then(() => {
                this.$refs.filtroConsulta.getDataFromApi();

                this.exibirAviso({
                  mensagem: "Cep atualizado com sucesso!",
                  tipo: "success"
                });
              })
              .catch(error => {
                this.exibirAviso({
                  mensagem: error.response.data.msg,
                  tipo: "error"
                });
              });
        } else {
          await cepService
              .criarCep(this.cepEditado)
              .then(() => {
                if (this.$refs.filtroConsulta.mostrarResultado) {
                  this.$refs.filtroConsulta.getDataFromApi();
                }

                this.exibirAviso({
                  mensagem: "Cep criado com sucesso!",
                  tipo: "success"
                });
              })
              .catch(error => {
                this.exibirAviso({
                  mensagem: error.response.data.msg,
                  tipo: "error"
                });
              });
        }
        this.cancelarEdicaoCep();

        loader.hide();
    }
    },

    cancelarEdicaoCep() {
      this.dialog = false;
      setTimeout(() => {
        this.$refs.edicaoCEPForm.reset();
        this.cepEditado = {};
      }, 500);
    }
  }
};
</script>

<style scoped></style>
